.video-slider-container {
  max-width: 1800px; 
  height: 150vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: none; 
  margin: 0 auto;
  padding-top: 80px;
  overflow: hidden; 
}
.slider-header {
  width: 100%;
  padding: 20px 10px; 
  background-color: #f5f5f5;
  text-align: center;
  padding-top: 60px;
}

.slider-header h2 {
  margin: 0 auto;
  color: #333;
  font-size: 2rem; 
}

.navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  flex-wrap: wrap;
  z-index: 5;
}

.navigation button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 10px 15px; /* Adjusted padding for more compact navigation */
  font-size: 1rem;
  transition: color 0.3s ease;
}

/* Active navigation item styling */
.navigation button.active {
  color: #007bff;
  font-weight: bold;
  position: relative;
}

/* Active item underline effect */
.navigation button.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 3px;
  width: 100%;
  background-color: #007bff;
  animation: fillBar 5s linear forwards;
}



.video-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  padding: 20px;
  background-color: #fff;
}
@media (min-width: 576px) { 
  .text-content {
    font-size: 1.1em; /* Slightly larger font size for better readability */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .text-content {
    font-size: 1.2em; /* Larger font size for tablet screens */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .text-content {
    font-size: 1.3em; /* Larger font size for desktops */
  }
}
@media (min-width: 1024px) {
  .video-content {
    flex-direction: row; /* Horizontal layout on desktop */
    align-items: center; /* Aligns items at the start */
    justify-content: space-between;
    padding: 2vh 4vw; /* Responsive padding based on viewport */
  }

  video {
    width: 60%; /* Relative width for video */
    height: auto; /* Maintains aspect ratio */
    max-height: 75vh; /* Maximum height to prevent overflow */
  }

  .text-content {
    font-size: 2em;
    width: 35%; /* Relative width for text content */
    margin-left: 20px; /* Space between video and text */
    overflow-y: auto; /* Enables scrolling for overflow text */
    max-height: 75vh; /* Limits text content height */
  }
}

video {
  width: 90%;
  max-height: 50vh;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.text-content {
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  color: #333;
  text-align: left;
  line-height: 1.6;
  overflow-y: auto;
  padding: 20px;
  font-size: 1em;
  transition: all 0.3s ease-in-out;
  border-left: 5px solid #007bff; /* Adds a colored border for emphasis */
  position: relative;
  z-index: 10;
}

.text-content:hover {
  background-color: #e9ecef; /* Changes background on hover */
  transform: scale(1.02); /* Slight scale effect on hover */
}

.text-content p, .text-content ul {
  animation: fadeIn 2s; /* Adds a fade-in animation for text */
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.text-content i {
  margin-right: 10px; /* Space between icons and text */
  color: #007bff; /* Color for icons */
}

.text-content strong {
  color: #0056b3; /* Darker color for emphasis */
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}
@keyframes fillBar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
