.feature-divider {
    width: 50px;
    height: 4px;
    background: #007bff;
    margin: 0.5rem auto;
  }
  
  .feature-icon {
    margin-bottom: 1rem;
  }
.feature-item {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .feature-icon {
    margin-bottom: 1rem;
  }
  
  h4 {
    margin-top: 1rem;
    font-weight: bold;
  }
  
  p {
    color: #666;
  }