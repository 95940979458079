.contact-section {
    background: linear-gradient(to right, #6a11cb, #2575fc);
    padding: 4rem 0;
    color: #fff;
  }
  
  .contact-title {
    font-weight: bold;
    font-size: 2rem;
  }
  
  .contact-divider {
    width: 50px;
    height: 3px;
    background-color: #fff;
    margin: 0.5rem 0 1.5rem 0;
  }
  
  .contact-description {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  
  .contact-form {
    padding-right: 2rem;
  }
  
  .contact-info {
    padding-left: 2rem;
  }
  
  .info-list {
    list-style: none;
    padding: 0;
  }
  
  .info-list li {
    font-size: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }
  
  .info-list i {
    margin-right: 0.5rem;
  }
  
  .send-message-button {
    border-radius: 50px;
    border: 2px solid #fff;
    padding: 0.5rem 2rem;
    font-weight: bold;
    color: #fff;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .send-message-button:hover {
    background-color: #fff;
    color: #6a11cb;
  }
  