.about-title {
    font-weight: bold;
    font-size: 2rem;
  }
  
  .about-divider {
    width: 50px;
    height: 4px;
    background-color: #007bff;
    /* background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%); */
    margin: 0.5 auto 1.5rem auto;
  }
  
  .about-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  .why-choose-us-title {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  
  .about-list {
    list-style: none;
    padding: 0;
  }
  
  .about-list li {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  